<template>
  <v-container class="card card-custom card-stretch card-shadowless gutter-b">
    <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
      <div
        v-if="isLoading"
        class="d-flex align-center justify-center flex-column py-15"
      >
        <v-progress-circular
          indeterminate
          color="primary"
          class="mb-4 mx-auto"
        ></v-progress-circular>
        <p class="text-body-1 font-weight-bold text-center">
          Calculando progreso...
        </p>
      </div>
    </v-scroll-x-transition>
    <!--begin::Header-->
    <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
      <div v-if="!isLoading">
        <div class="card-header border-0 pt-5 d-flex justify-center">
          <div class="card-title font-weight-bold mb-n8">
            <div class="card-label text-center text-h4">
              {{ timeOfDayMessage }}
              <div class="text-body-1 text-muted mt-1">
                Progreso de año electivo {{ currentYear }}
              </div>
            </div>
          </div>
        </div>
        <!--end::Header-->
        <!-- begin:: No info available fallback -->
        <div v-if="!isLoading && !isYearlyProgressAvailable" class="pa-5">
          <div class="d-flex align-center justify-center flex-column py-15">
            <v-icon class="text-h1" color="blue lighten-1"
              >mdi-information-outline</v-icon
            >
            <p class="text-body-1 font-weight-bold text-center">
              No hay información disponible aún.
            </p>
          </div>
        </div>
        <!-- end:: No info available fallback -->
        <!--begin::Body-->
        <div v-else class="card-body px-0 px-sm-2 px-md-4">
          <!--begin::Chart-->
          <div class="py-0">
            <apexchart
              :options="chartOptions"
              :series="series"
              type="radialBar"
            ></apexchart>
          </div>
          <!--end::Chart-->
          <!--begin::Items-->
          <div class="mt-4 position-relative zindex-0">
            <div v-for="(item, i) in list" :key="i">
              <div
                class="d-flex align-items-center"
                :class="{ 'mb-8': lastElement(i) }"
              >
                <!--begin::Symbol-->
                <div
                  :class="`pa-3 rounded-circle mr-3 ${item.color} lighten-4`"
                >
                  <div class="symbol-label">
                    <span :class="`svg-icon svg-icon-lg`">
                      <v-icon :color="`${item.color}`">
                        {{ item.svg }}
                      </v-icon>
                    </span>
                  </div>
                </div>
                <!--end::Symbol-->
                <!--begin::Title-->
                <div>
                  <p
                    href="#"
                    class="font-size-h6 text-dark-75 text-hover-primary font-weight-bolder mb-0"
                  >
                    {{ item.title }}
                  </p>
                  <div class="font-size-sm text-muted font-weight-bold mt-1">
                    {{ item.desc }}
                  </div>
                </div>
                <!--end::Title-->
              </div>
            </div>
          </div>
          <!--end::Items-->
        </div>
        <!--end::Body-->
      </div>
    </v-scroll-x-transition>
  </v-container>
</template>

<script>
import Dropdown3 from "@/view/content/dropdown/Dropdown3.vue";
import { mapGetters } from "vuex";

export default {
  name: "widget-4",
  components: {
    Dropdown3,
  },
  props: {
    isLoading: {
      type: Boolean,
    },
    yearProgress: {
      type: Number,
      default: 0,
    },
    elapsedDays: {
      type: Number,
    },
    totalDays: {
      type: Number,
    },
    timeOfDayMessage: {
      type: String,
    },
    currentPeriodDetails: {
      type: Object,
    },
    isYearlyProgressAvailable: {
      type: Boolean,
    },
  },
  data() {
    return {
      list: [],

      PAYMENT_STATUS: {
        solvent: {
          title: "¡Todo bien!",
          desc: "No tiene colegiaturas en mora.",
          icon: "mdi-check-circle-outline",
          color: "green",
        },
        insolvent: {
          title: "¡Atención!",
          desc: "En nuestros registros usted cuenta con colegiaturas pendientes.",
          icon: "mdi-alert-rhombus-outline",
          color: "deep-orange",
        },
      },

      chartOptions: {},
      series: [],
    };
  },
  watch: {
    "currentPeriodDetails.stageName": function (newValue, oldValue) {
      if (!!newValue) {
        this.setStats();
        this.$emit("setDisciplineStats");
      }
    },
  },
  methods: {
    setStats() {
      let currentStage = {
        title: this.currentPeriodDetails.stageName,
        desc: this.currentPeriodDetails.todayDate,
        svg: "mdi-bell-badge-outline",
        color: "indigo",
      };

      let paymentStatus;

      this.currentUserPersonalInfo.payment_status
        ? (paymentStatus = {
            title: `${this.PAYMENT_STATUS.solvent.title}`,
            desc: `${this.PAYMENT_STATUS.solvent.desc}`,
            svg: `${this.PAYMENT_STATUS.solvent.icon}`,
            color: `${this.PAYMENT_STATUS.solvent.color}`,
          })
        : (paymentStatus = {
            title: `${this.PAYMENT_STATUS.insolvent.title}`,
            desc: `${this.PAYMENT_STATUS.insolvent.desc}`,
            svg: `${this.PAYMENT_STATUS.insolvent.icon}`,
            color: `${this.PAYMENT_STATUS.insolvent.color}`,
          });

      let elapsedDaysFromTotal = {
        title: `Día #${this.elapsedDays} del año electivo ${this.currentYear}`,
        desc: `De ${this.totalDays} dias del año electivo escolar en ${this.currentYear}`,
        svg: "mdi-calendar-month-outline",
        color: "cyan",
      };

      if (this.currentPeriodDetails.showTextualProgressIndicators) {
        this.series = [this.yearProgress];
        this.list = [elapsedDaysFromTotal, currentStage];
        // this.list = [elapsedDaysFromTotal, currentStage, paymentStatus];
      } else {
        this.series = [100];
        this.list = [];
        // this.list = [paymentStatus];
      }

      this.$emit("statsLoaded");
    },

    lastElement(i) {
      if (i === this.list.length - 1) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "currentUserPersonalInfo"]),
    currentYear() {
      return new Date().getFullYear();
    },
  },
  mounted() {
    this.chartOptions = {
      chart: {
        height: 290,
        type: "radialBar",
        offsetY: 0,
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,

          hollow: {
            margin: 0,
            size: "60%",
          },
          dataLabels: {
            showOn: "always",
            name: {
              show: true,
              fontSize: "14px",
              fontWeight: "500",
              offsetY: 0,
              color: this.layoutConfig("colors.gray.gray-500"),
            },
            value: {
              color: this.layoutConfig("colors.gray.gray-700"),
              fontSize: "28px",
              fontWeight: "600",
              offsetY: -30,
              show: true,
            },
          },
          track: {
            background: "#E1F5FE",
            // background: this.layoutConfig("colors.theme.light.primary"),
            strokeWidth: "100%",
          },
        },
      },
      colors: ["#29B6F6"],
      // colors: [this.layoutConfig("colors.theme.base.primary")],
      stroke: {
        lineCap: "round",
      },
      labels: ["completado"],
    };
  },
};
</script>
