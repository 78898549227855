<template>
  <div>
    <!--begin::Dashboard-->

    <v-row>
      <v-col cols="12" xl="4">
        <!-- First row (pagos, formularios, descargas de seguro y talonario) -->
        <v-row>
          <v-col cols="12" v-if="isFromAdmission && !isEnrolled">
            <p class="display-2">¡Bienvenido/a!</p>
          </v-col>
          <!-- Card de pagos en línea -->
          <v-col cols="12" md="4" xl="6">
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="orange"
                title="Pago en línea"
                subTitle="¡Ahora puedes realizar los pagos de la institución en línea!"
                icon="currency-usd"
                buttonText="Realizar pagos"
                @openDetailsModal="goToPaymentsView()"
              />
            </div>
          </v-col>
          <!-- Card de mostrar formulario de actualización de datos -->
          <v-col
            v-if="mostrarAlertaFormularioEstudiante"
            cols="12"
            md="4"
            xl="6"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="blue"
                title="Formulario de actualización de datos"
                subTitle="Formulario de registro de datos del estudiante y responsables ante la institución."
                icon="account-outline"
                buttonText="Completar formulario"
                @openDetailsModal="openStudentForm()"
              />
            </div>
          </v-col>
          <!-- Card de registro de sostenedor económico -->
          <v-col
            v-if="currentUserPersonalInfo.show_economic_manager_update_form"
            cols="12"
            md="4"
            xl="6"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="blue"
                title="Sostenedor económico"
                subTitle="Formulario de registro de sostenedor económico del estudiante ante la institución."
                icon="account-outline"
                buttonText="Registrar sostenedor"
                @openDetailsModal="openRegisterEconomicManagerModal()"
              />
            </div>
          </v-col>
          <!-- Card descargar seguro  -->
          <v-col
            v-if="currentUserPersonalInfo.seguro !== null"
            cols="12"
            md="4"
            xl="6"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="blue"
                title="Carnet de asegurado"
                subTitle="Descarga el carnet de seguro de accidentes"
                icon="lifebuoy"
                buttonText="Descargar carnet seguro"
                @openDetailsModal="downloadCarnetSeguro()"
              />
            </div>
          </v-col>
          <!-- Card descargar talonario -->
          <v-col
            v-if="currentUserPersonalInfo.talonario !== null"
            cols="12"
            md="4"
            xl="12"
          >
            <div
              class="white elevation-0 rounded-lg pa-5 card card-custom card-stretch card-shadowless"
            >
              <IconCardWithActionButtonVertical
                colorTheme="purple"
                title="Talonario de pagos"
                subTitle="Descarga el talonario de pagos para pagar en uno de los bancos autorizados (Banco Davivienda, Banco Agrícola o Banco Promerica)"
                icon="currency-usd"
                buttonText="Descargar talonario"
                @openDetailsModal="downloadTalonario()"
              />
            </div>
          </v-col>
        </v-row>
      </v-col>

      <!-- Card de progreso anual -->
      <v-col v-if="isEnrolled" cols="12" md="4" xl="3">
        <ProgresoAnualWidget
          :isLoading="widgets.areStatsLoading"
          :isYearlyProgressAvailable="isYearlyProgressAvailable"
          :currentPeriodDetails="yearlyProgress.activeStage"
          :yearProgress="yearlyProgress.elapsedYearPercent"
          :totalDays="yearlyProgress.totalDaysInYear"
          :elapsedDays="yearlyProgress.elapsedDays"
          :timeOfDayMessage="yearlyProgress.timeOfDayMessage"
          @statsLoaded="test()"
          @setDisciplineStats="loadDisciplineStats()"
        ></ProgresoAnualWidget>
      </v-col>
      <!-- Card de estadísticas de disciplina -->
      <v-col v-if="isEnrolled" cols="12" md="8" lg="8" xl="5">
        <InformacionDisciplinaWidget
          :isYearlyProgressAvailable="isYearlyProgressAvailable"
          :sortedStages="yearlyProgress.sortedStages"
          :activeStage="yearlyProgress.activeStage"
          :isLoading="widgets.areStatsLoading"
        ></InformacionDisciplinaWidget>
      </v-col>
    </v-row>

    <!-- begin:: Card de últimas evaluaciones calificadas -->
    <div
      v-if="widgets.latestGradedSubevaluations.length > 0"
      class="card card-custom gutter-b pa-6"
    >
      <v-row>
        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-col
            v-if="!widgets.isLatestGradedSubevaluationsLoading"
            class=""
            cols="12"
          >
            <p class="text-center display-1 mb-0 ml-2">
              Últimas evaluaciones calificadas
            </p>
          </v-col>
        </v-scroll-x-transition>

        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-col v-if="widgets.isLatestGradedSubevaluationsLoading" cols="12">
            <v-card
              height="250"
              class="pa-4 rounded-lg d-flex justify-center align-center flex-column elevation-0"
            >
              <v-progress-circular
                :size="50"
                indeterminate
                color="primary"
                class="mb-4"
              ></v-progress-circular>
              <p class="text-center font-weight-medium text-body-1">
                Buscando últimas evaluaciones calificadas...
              </p>
            </v-card>
          </v-col>
        </v-scroll-x-transition>

        <v-scroll-x-transition :hide-on-leave="true" :leave-absolute="true">
          <v-row
            v-if="!widgets.isLatestGradedSubevaluationsLoading"
            class="mx-2"
          >
            <v-col
              cols="12"
              sm="6"
              md="4"
              xl="3"
              v-for="(
                subEvaluation, index
              ) in widgets.latestGradedSubevaluations"
              :key="index"
            >
              <ListWidget11 :subEvaluation="subEvaluation"></ListWidget11>
            </v-col>
          </v-row>
        </v-scroll-x-transition>
      </v-row>
    </div>
    <!-- end:: Card de últimas evaluaciones calificadas -->

    <!-- Formulario de actualización o registro de sostedor económico -->
    <registerStudentEconomicManagerModal
      ref="mdlRegisterStudentEconomicManager"
    ></registerStudentEconomicManagerModal>

    <!-- begin:: Alerta de llenar el formulario de actualización de datos -->
    <v-dialog
      v-model="mostrarAlertaFormularioEstudiante"
      persistent
      max-width="660"
      transition="dialog-bottom-transition"
    >
      <v-card class="py-25">
        <v-card-title class="headline d-flex flex-column justify-center mb-3">
          <v-avatar color="primary" size="86" class="mb-2">
            <v-icon x-large dark>mdi-account-alert</v-icon>
          </v-avatar>
          ¡Atención!
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <p class="text-body-1">
              Debes completar el formulario de información del estudiante como
              parte del procedimiento de matricula para el año lectivo 2025.
            </p>
          </div>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="primary" dark @click="openStudentForm">
            <v-icon left>mdi-form-select</v-icon>
            Llenar Formulario
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end:: Alerta de llenar el formulario de actualización de datos -->
  </div>
</template>

<script>
import dashboardRepository from "@/repositories/dashboardRepository";
import { getStudentFormStatusByStudentUuid } from "@/repositories/form/studentsRepository";
import IconCardWithActionButtonVertical from "@/view/components/dashboard/IconCardWithActionButtonVertical";
import registerStudentEconomicManagerModal from "@/view/components/modals/payments/registerStudentEconomicManagerModal.vue";

import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ProgresoAnualWidget from "@/view/content/widgets/list/Widget3.vue";
import InformacionDisciplinaWidget from "@/view/content/widgets/list/Widget4.vue";

// -> Imports de widgets que venían en la plantilla original
// import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
// import ListWidget2 from "@/view/content/widgets/list/Widget2.vue";
// import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
// import ListWidget5 from "@/view/content/widgets/list/Widget5.vue";
// import ListWidget10 from "@/view/content/widgets/list/Widget10.vue";
// import ListWidget11 from "@/view/content/widgets/list/Widget11.vue";
// import ListWidget12 from "@/view/content/widgets/list/Widget12.vue";
// import ListWidget13 from "@/view/content/widgets/list/Widget12.vue";
// import ListWidget14 from "@/view/content/widgets/list/Widget14.vue";
// import ListWidget15 from "@/view/content/widgets/list/Widget15.vue";
// import ListWidget16 from "@/view/content/widgets/list/Widget16.vue";

export default {
  name: "dashboard",
  components: {
    IconCardWithActionButtonVertical,
    registerStudentEconomicManagerModal,
    ProgresoAnualWidget,
    InformacionDisciplinaWidget,
    // ->Widgets que venían en la plantilla original
    // AdvancedTableWidget2,
    // MixedWidget1,
    // ListWidget2,
    // ListWidget5,
    // ListWidget10,
    // ListWidget11,
    // ListWidget12,
    // ListWidget13,
    // ListWidget14,
    // ListWidget15,
    // ListWidget16,
  },
  data() {
    return {
      widgets: {
        isLatestGradedSubevaluationsLoading: false,
        areStatsLoading: true,
        latestGradedSubevaluations: [],
        stageDetails: {},
        paymentStatus: {},
        stages: [],
      },
      yearlyProgress: {},
      studentInformationForm: {
        finishedForm: null,
        formStatus: null,
      },
      mostrarAlertaFormularioEstudiante: false,
    };
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
    // this.getLatestGradedSubevaluations();

    // Check if the student has a grade_id
    if ("grade_id" in this.currentUserPersonalInfo) {
      this.getYearlyProgress();
    }

    // Check if the student has to complete the student information form
    if ("economic_manager_exists" in this.currentUserPersonalInfo) {
      if (
        !this.currentUserPersonalInfo.economic_manager_exists ||
        this.currentUserPersonalInfo.show_economic_manager_update_form
      ) {
        this.openRegisterEconomicManagerModal();
      }
    }

    // Check if the student has to complete the student information form
    if ("uuid" in this.currentUserPersonalInfo) {
      this.checkIfStudentMustCompleteStudentInformation();
    }
  },
  watch: {
    "currentUserPersonalInfo.grade_id": function (newValue, oldValue) {
      if (!!newValue) {
        // Triggered if the grade_id is set
        this.getYearlyProgress();
      }
    },
    "currentUserPersonalInfo.uuid": function (newValue) {
      if (!!newValue) {
        // Triggered if the uuid is set
        this.checkIfStudentMustCompleteStudentInformation();
      }
    },
  },
  methods: {
    openStudentForm() {
      window.location.replace(
        `${this.studentFormUrl}/student/${this.currentUserPersonalInfo.uuid}`
      );
    },
    openRegisterEconomicManagerModal() {
      this.$refs.mdlRegisterStudentEconomicManager.toggleModal();
    },
    getYearlyProgress() {
      this.widgets.areStatsLoading = true;
      dashboardRepository
        .getStudentStagesByGrade(this.currentUserPersonalInfo.grade_id)
        .then(({ data }) => {
          this.yearlyProgress = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.widgets.areStatsLoading = false;
        });
    },
    goToPaymentsView() {
      this.$router.push({
        path: "online-payments",
      });
    },

    downloadTalonario() {
      try {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.currentUserPersonalInfo.talonario;
        downloadLink.download = `${this.currentUserPersonalInfo.code}-talonario.pdf`; // Change 'output.pdf' to the desired filename
        downloadLink.click();
      } catch (error) {
        this.fireToast({
          icon: "error",
          title: "Hubo un error al intentar descargar el talonario",
        });
      }
    },

    downloadCarnetSeguro() {
      try {
        const downloadLink = document.createElement("a");
        downloadLink.href = this.currentUserPersonalInfo.seguro;
        downloadLink.download = `${this.currentUserPersonalInfo.code}-carnet-seguro.pdf`; // Change 'output.pdf' to the desired filename
        downloadLink.click();
      } catch (error) {
        this.fireToast({
          icon: "error",
          title: "Hubo un error al intentar descargar el carnet de seguro",
        });
      }
    },
    test() {
      this.widgets.areStatsLoading = false;
    },
    loadDisciplineStats() {
      //The current discipline stats will depend on the given current active stage.
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    // setActiveTab2(event) {
    //   this.tabIndex2 = this.setActiveTab(event);
    // },
    getLatestGradedSubevaluations() {
      this.widgets.isLatestGradedSubevaluationsLoading = true;

      dashboardRepository
        .getLatestGradedSubevaluations()
        .then(({ data }) => {
          this.widgets.latestGradedSubevaluations = data;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.widgets.isLatestGradedSubevaluationsLoading = false;
        });
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
    checkIfStudentMustCompleteStudentInformation() {
      getStudentFormStatusByStudentUuid(this.currentUserPersonalInfo.uuid)
        .then(({ data }) => {
          if (data === null) {
            this.mostrarAlertaFormularioEstudiante = false;
            return;
          }
          this.studentInformationForm = data;
          this.mostrarAlertaFormularioEstudiante =
            data.formStatus != process.env.FINISH_FORM_STATUS &&
            data.finishedForm === null;
        })
        .catch((error) => {
          this.fireToast({
            icon: "error",
            title:
              "Hubo un error al verificar el estado del formulario de estudiante",
          });
          console.error(
            "Error al obtener el estado del formulario de estudiante: ",
            error.message
          );
        });
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    admisionURL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.ADMISION_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.ADMISION_DEV_API_URL;
      } else {
        baseURL = process.env.ADMISION_LOCAL_API_URL;
      }
      return baseURL;
    },
    // Variable para ocultar menús si es de nuevo ingreso o tiene proceso de matrícula pendiente
    isFromAdmission() {
      return (
        this.currentUserPersonalInfo.is_from_admission ||
        this.currentUserPersonalInfo.is_enrolled == false
      );
    },

    isEnrolled() {
      return this.currentUserPersonalInfo.is_enrolled;
    },

    hasCompletedForm() {
      return (
        this.studentInformationForm.formStatus ==
          process.env.FINISH_FORM_STATUS &&
        this.studentInformationForm.finishedForm != null
      );
    },
    studentFormUrl() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.FORM_APP_PRODUCTION_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.FORM_APP_DEVELOPMENT_URL;
      } else {
        baseURL = process.env.FORM_APP_DEVELOPMENT_URL;
      }
      return baseURL;
    },
    studentCode() {
      return this.currentUserPersonalInfo.code;
    },
    gradeId() {
      return this.currentUserPersonalInfo.grade_id;
    },
    specialityId() {
      return this.currentUserPersonalInfo.specialityId_id;
    },
    currentPeriod() {
      const today = new Date();
    },
    currentDate() {
      const today = new Date();
      const formatter = new Intl.DateTimeFormat("es-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
      const fullDate = formatter.format(today);
      return fullDate;
    },
    isYearlyProgressAvailable() {
      return Object.keys(this.yearlyProgress).length > 0;
    },
  },
};
</script>
